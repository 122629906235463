.product {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 0;
  
  
}
.table {
  width:100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  margin-top: -135px;

}

.productImage  {
  width: 100%;
  height: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
}


.containerprime  {
  display: flex;
  flex-direction: column;
  height: 35%;
  align-items: center;
  margin-top: -100px;
  padding-bottom: 2.5rem;
}



 .containerprime h1 {
  font-weight: 560;
  font-size: 55px;
  color: black;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-200px);
  
}

@media (max-width:700px) {
  .containerprime h1{
    font-size:30px;
    transform: translateY(-100px);
  }
}


/* MENU ITEM STYLING */

/* .menuItem {
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.menuItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.menuItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menuItem h1,
.menuItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .menuList {
    grid-template-columns: 1fr;
  }
} */
