.contact{
  width: 100%;
  height: 200vh;
  display: flex;
  justify-content:baseline;
  align-items: left;
  flex-direction: column;
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
}