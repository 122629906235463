.card-container{
    width: 370px;
    height: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    animation: ease-in;
    background-color:#D3D3D3;
    border-radius: 8px;
    margin-bottom: 0.8rem;
}
.card-container:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}
.card-content{
    margin: 1rem;
    margin-top: 0.5rem;

}
.card-body p{
    margin:0;
    padding:0;
    margin-bottom: 3rem;
}
.card-title{
    margin-bottom: 2rem;
}
.card-title h1{
    font-size: 2.4rem;
}
.btn{
    display:flex;
    justify-content:center ;
    
}
.btn button{
    padding: 0.5rem;
    background-color:#D3D3D3;
    border: none;
    transition: 0.2s;
    margin-bottom: 2.3rem;
    border-radius: 3px;
}
.btn button:hover{
    background: rgba(27,156,252,0.1);
    transform: scale(1.1);

}
.btn a{
    text-transform: uppercase;
    color: #189CFC;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

}