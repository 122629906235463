@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
*{
  font-family: 'Sora', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} 
.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  padding: 0;
  margin: 0;
}
.menu {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .menu .menuTitle {
    
    font-size: 40px;
  }
  
  .menuList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
   /* .menuTitle button {
    
    border: 2px;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
   
    height: 60px;
    width: 180px;
    
    text-decoration: none;
    font-size: 15px;
  }  */
  
  /* MENU ITEM STYLING */
  
  .menuItem {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }
  .menuItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .menuItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .menuItem h1,
  .menuItem p {
    margin-left: 20px;
  }
  
  @media only screen and (max-width: 1300px) {
    .menuList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .menuList {
      grid-template-columns: 1fr;
    }
  }
  
:root{
 --primary: #000C66;
 --warning:#ffd028;
--danger:#eb3f27;
 --success:#75fa83;
 --white:#fdfdfd;
 --dark:#181717;

}
.btn{
    font-weight:400;
    border-radius: 10px;
    cursor: pointer;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-top: 15px;


   
    
   
   

}
.btn:hover{
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);

}
.btn--primary--solid{
 background-color: #000C66;
 background-color: var(--primary);
 color: #fdfdfd;
 color: var(--white);
 border: none;
}
.btn--warning--solid{
 background-color: #ffd028;
 background-color: var(--warning);
 color:#fdfdfd;
 color:var(--white);
 border:none;
}
.btn--danger--solid{
 background-color: #eb3f27;
 background-color: var(--danger);
 color: #fdfdfd;
 color: var(--white);
 border: none;
}
.btn--success--solid{
 background-color: #75fa83;
 background-color: var(--success);
 color:#fdfdfd;
 color:var(--white);
 border: none;
}
.btn--primary--outline{
 background-color: transparent;
 border: 2px solid #181717;
 border: 2px solid var(--dark);
 color:#181717;
 color:var(--dark);

}
.btn--warning--outline{
 background-color: transparent;
 border: 2px solid #ffd028;
 border: 2px solid var(--warning);
 color:#ffd028;
 color:var(--warning);
}
.btn--danger--outline{
 background-color: transparent;
 border: 2px solid #eb3f27;
 border: 2px solid var(--danger);
 color: #eb3f27;
 color: var(--danger);
}
.btn--success--outline{
 background-color: transparent;
 border:2px solid #75fa83;
 border:2px solid var(--success);
 color:#75fa83;
 color:var(--success);
}
.btn--medium{
    padding:10px 20px;
    font-size: 18px;
}
.btn--large{
    padding:15px 30px;
    font-size:20px;
}

table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    height: 45%;
    
    
  }
 
  
  table td, table th {
    border: 1px solid #ddd;
    padding: 12px;
  }
  
  table tr:nth-child(even){background-color: #f2f2f2;}
  
  table tr:hover {background-color: #ddd;}
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #04AA6D;
    color: white;
  }
.product {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 0;
  
  
}
.table {
  width:100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  margin-top: -135px;

}

.productImage  {
  width: 100%;
  height: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: flex;
}


.containerprime  {
  display: flex;
  flex-direction: column;
  height: 35%;
  align-items: center;
  margin-top: -100px;
  padding-bottom: 2.5rem;
}



 .containerprime h1 {
  font-weight: 560;
  font-size: 55px;
  color: black;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transform: translateY(-200px);
          transform: translateY(-200px);
  
}

@media (max-width:700px) {
  .containerprime h1{
    font-size:30px;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}


/* MENU ITEM STYLING */

/* .menuItem {
  border-radius: 15px;
  width: 300px;
  height: 350px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.menuItem:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.menuItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menuItem h1,
.menuItem p {
  margin-left: 20px;
}

@media only screen and (max-width: 1300px) {
  .menuList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .menuList {
    grid-template-columns: 1fr;
  }
} */

 .about {
 width: 100%;
 height: 100%;
 position: relative;
 align-items: center;
 justify-content: center;

} 
 .slid{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
} 
 .aboutBottom{
  display: flex;
  flex-direction: column;
}
.about h1{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.aboutTop {
  width: 100%;
  height: 80%;
  background-position: center;
   background-repeat: no-repeat;
   background-size: cover;   
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  height: 45%;
  align-items: center;
  margin: 20px;
  
}

.aboutBottom p {
  width: 1200px;
  
}



#main-slider-container{
    width: 90%;
    height: 310px;
    
    
}

#slider{
    width:100%;
    height:100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}
#slider::-webkit-scrollbar{
    display: none;
}
#main-slider-container{
    position: relative;
    display: flex;
    align-items: center;
}
.arrow{
    border:2px solid black;
    border-radius: 50%;
    background: transparent;
    color:black;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    opacity: 0.5;

 }
 .arrow:hover{
    background: black;
    color:#777;

 }
 .next{
    top: 35%;
    right:1.5rem;
 }
 .prev {
    top: 35%;
    left:1.5rem;
 }
 .slider-card{
    width: 320px;
    height: 300px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%) ;
    cursor: pointer;
 }
 .slider-card-image{
    width: 100%;
    height: 220px;
    background-color: hsl(0deg 0% 94%/80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
 }
 .slider-card-title, .slider-card-description{
    margin: 10px 0px 5px 10px; 
    
 }
 .slider-card-title{
   font-weight: 800;
   margin-top: 10px;
 }
.slider-card-description{
opacity: 0.5;
font-size: 13px;
}

.slider{width:100%;height:90vh;position:relative;overflow:hidden}.slide{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;-webkit-transform:translateX(-50%);transform:translateX(-50%);transition:all .5s ease}@media screen and (min-width: 600px){.slide img{width:100%;height:100%}}.slide img{height:100%}.content{position:absolute;top:23rem;left:5rem;opacity:0;width:50%;padding:3rem;background:rgba(0,0,0,.3);-webkit-animation:slide-up 1s ease .5s;animation:slide-up 1s ease .5s;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards}@-webkit-keyframes slide-up{0%{visibility:visible;top:23rem}100%{visibility:visible;top:17rem}}@keyframes slide-up{0%{visibility:visible;top:23rem}100%{visibility:visible;top:17rem}}@media screen and (max-width: 600px){.content{width:80%}}.content>*{color:#fff;margin-bottom:1rem}.current{opacity:1;-webkit-transform:translateX(0);transform:translateX(0)}.current .content{opacity:1}.arrow{border:2px solid #000;border-radius:50%;background:rgba(0,0,0,0);color:#000;width:2rem;height:2rem;cursor:pointer;position:absolute;z-index:999}.arrow:hover{background:#000;color:#777}.next{top:35%;right:1.5rem}.prev{top:35%;left:1.5rem}hr{height:2px;background:#fff;width:50%}
.card-container{
    width: 370px;
    height: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.3s;
    -webkit-animation: ease-in;
            animation: ease-in;
    background-color:#D3D3D3;
    border-radius: 8px;
    margin-bottom: 0.8rem;
}
.card-container:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}
.card-content{
    margin: 1rem;
    margin-top: 0.5rem;

}
.card-body p{
    margin:0;
    padding:0;
    margin-bottom: 3rem;
}
.card-title{
    margin-bottom: 2rem;
}
.card-title h1{
    font-size: 2.4rem;
}
.btn{
    display:flex;
    justify-content:center ;
    
}
.btn button{
    padding: 0.5rem;
    background-color:#D3D3D3;
    border: none;
    transition: 0.2s;
    margin-bottom: 2.3rem;
    border-radius: 3px;
}
.btn button:hover{
    background: rgba(27,156,252,0.1);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

}
.btn a{
    text-transform: uppercase;
    color: #189CFC;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

}
.contact{
  width: 100%;
  height: 200vh;
  display: flex;
  justify-content:baseline;
  align-items: left;
  flex-direction: column;
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
}
