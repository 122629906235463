#main-slider-container{
    width: 90%;
    height: 310px;
    
    
}

#slider{
    width:100%;
    height:100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}
#slider::-webkit-scrollbar{
    display: none;
}
#main-slider-container{
    position: relative;
    display: flex;
    align-items: center;
}
.arrow{
    border:2px solid black;
    border-radius: 50%;
    background: transparent;
    color:black;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    opacity: 0.5;

 }
 .arrow:hover{
    background: black;
    color:#777;

 }
 .next{
    top: 35%;
    right:1.5rem;
 }
 .prev {
    top: 35%;
    left:1.5rem;
 }
 .slider-card{
    width: 320px;
    height: 300px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%) ;
    cursor: pointer;
 }
 .slider-card-image{
    width: 100%;
    height: 220px;
    background-color: hsl(0deg 0% 94%/80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
 }
 .slider-card-title, .slider-card-description{
    margin: 10px 0px 5px 10px; 
    
 }
 .slider-card-title{
   font-weight: 800;
   margin-top: 10px;
 }
.slider-card-description{
opacity: 0.5;
font-size: 13px;
}
