:root{
 --primary: #000C66;
 --warning:#ffd028;
--danger:#eb3f27;
 --success:#75fa83;
 --white:#fdfdfd;
 --dark:#181717;

}
.btn{
    font-weight:400;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-top: 15px;


   
    
   
   

}
.btn:hover{
    transform: translateY(-3px);

}
.btn--primary--solid{
 background-color: var(--primary);
 color: var(--white);
 border: none;
}
.btn--warning--solid{
 background-color: var(--warning);
 color:var(--white);
 border:none;
}
.btn--danger--solid{
 background-color: var(--danger);
 color: var(--white);
 border: none;
}
.btn--success--solid{
 background-color: var(--success);
 color:var(--white);
 border: none;
}
.btn--primary--outline{
 background-color: transparent;
 border: 2px solid var(--dark);
 color:var(--dark);

}
.btn--warning--outline{
 background-color: transparent;
 border: 2px solid var(--warning);
 color:var(--warning);
}
.btn--danger--outline{
 background-color: transparent;
 border: 2px solid var(--danger);
 color: var(--danger);
}
.btn--success--outline{
 background-color: transparent;
 border:2px solid var(--success);
 color:var(--success);
}
.btn--medium{
    padding:10px 20px;
    font-size: 18px;
}
.btn--large{
    padding:15px 30px;
    font-size:20px;
}
