.slider{
    width:100%;
    height:90vh;
    position: relative;
    overflow: hidden;

}

.slide{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all .5s ease;
}
@media screen and (min-width:600px)  {
    .slide img{
        width:100%;
        height:100%;
    }
}
.slide img{
    
    height:100%;
}

.content{
    position: absolute;
    top:23rem;
    left:5rem;
    opacity: 0;
    width:50%;
    padding: 3rem;
    background: rgba(0,0,0,0.3);
    animation: slide-up 1s ease .5s;
    animation-fill-mode: forwards;
   // visibility: hidden;
}
@keyframes slide-up{
    0% {
       visibility: visible;
       top: 23rem;

    }
    100% {
        visibility: visible;
        top: 17rem;
        
     }
}
@media screen and (max-width:600px) {
    .content{
        width: 80%;
    }
}
.content > *{
    color:white;
    margin-bottom: 1rem;
}
.current{
    opacity: 1;
    transform: translateX(0);
}
 .current .content{
    opacity: 1;
 }

 .arrow{
    border:2px solid black;
    border-radius: 50%;
    background: transparent;
    color:black;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    z-index: 999;

 }
 .arrow:hover{
    background: black;
    color:#777;

 }
 .next{
    top: 35%;
    right:1.5rem;
 }
 .prev {
    top: 35%;
    left:1.5rem;
 }
 hr{
    height: 2px;
    background: white;
    width: 50%;
 }